<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>

                <!-- Táblázat -->

				<DataTable ref="dt" :value="costs" v-model:expandedRows="expandedRows" dataKey="id" :paginator="false" :rows="25" :filters="filters"
                            sortField="costname" :sortOrder="1" :loading="loading" @filter="onFilter($event)" @row-click="expandRow"
                            :totalRecords="totalRecords" @sort="onSort($event)"
							class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div>
                                <Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
                                <h5 class="p-m-0 p-d-inline-block">Karbantartás: Költség típusok</h5>
                            </div>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="costname" header="Megnevezés" headerStyle="width: 86%" sortable></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editCost(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteCost(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                            </div>
                        </template>                            
                        </Card>
                    </template>
                </DataTable>


                <!-- Szerkesztő ablak -->

				<Dialog id="dialog" v-model:visible="costDialog" :style="{ width: '450px', 'margin-top': '16px' }" 
                    header="Tápszer" :modal="true" class="p-fluid"
                    @keydown.enter.ctrl="saveCost()" @keyup.esc="hideDialog()">
					<span class="p-field p-float-label">
						<InputText id="costname" v-model.trim.lazy="v$.cost.costname.$model" 
                            required="true" :class="{'p-invalid': v$.cost.costname.$invalid}" autocomplete="new-hosp-costname"
                        />
						<label for="costname">Megnevezés</label>
						<small id="costname-help" class="p-error" v-if="v$.cost.costname.$error">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<Textarea id="description" v-model="cost.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveCost"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteCostDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="cost">Biztosan töröljük a költség típust: <b>{{cost.costname}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteCostDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteCost"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import CostService from '@/service/backend/cost.service'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
		return {
			costs: null,
			costDialog: false,
			deleteCostDialog: false,
			deleteCostsDialog: false,
			cost: {},
			expandedRows: [],
            filters: {
                'global': {value: null},
            },
            submitted: false,
            searchGlobal: "",
            totalRecords: 0,
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

		}
	},
    validations: {
        cost: {
            costname: { required }
        }
    },
    costService: null,
	created() {
		this.costService = new CostService()
    },
    beforeMount() {
        //this.loadCodeStore('costClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.loadData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        loadData() {
            this.loading = true;

            this.costService.getCosts()
            .then( result => {
                this.costs = result
                this.loading = false
            })
        },
        onFilter() {
            this.loadingSearch = false
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.costs.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        openDialog(){
            this.costDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
		openNew() {
            this.cost = {}
            this.openDialog()
		},
		hideDialog() {
			this.costDialog = false
            this.submitted = false
		},
		async saveCost() {

            this.submitted = true;
            this.v$.cost.$touch()

            if (!this.v$.$invalid) {

                    try{
                        if (this.cost.id) {
                            this.cost.id = await this.costService.updateCost(this.cost)
                            this.costs[this.findIndexById(this.cost.id)] = this.cost
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.cost.id = await this.costService.newCost(this.cost)
                            this.costs.push(this.cost)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                    }catch{
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                    }finally{
                        this.submitted = false
                    }

                    this.hideDialog()
            
            }

            this.submitted = false

		},
		editCost(id) {
            this.cost = JSON.parse(JSON.stringify(this.costs.find( d => d.id === id)));
            this.openDialog()
		},
		confirmDeleteCost(cost) {
			this.cost = cost
			this.deleteCostDialog = true
		},
		async deleteCost() {
            this.submitted = true
            try{
                await this.costService.deleteCost(this.cost.id)
                this.costs = this.costs.filter(val => val.id !== this.cost.id)
                this.deleteCostDialog = false
                this.cost = {}
                this.$toast.add({severity:'success', summary: 'Siker', detail: 'Törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                this.submitted = false
                return
            }
		},
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.costs.length; i++) {
				if (this.costs[i].id === id) {
					index = i
					break
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		},
		/*confirmDeleteSelected() {
			this.deleteCostsDialog = true;
		},
		deleteSelectedCosts() {
			this.costs = this.costs.filter(val => !this.selectedCosts.includes(val));
			this.deleteCostsDialog = false;
			this.selectedCosts = null;
			this.$toast.add({severity:'success', summary: 'Siker', detail: 'Costs Deleted', life: 3000});
        },*/
        ctrlEntered() {
            //saveCost
            console.log('ctrlEntered()')
        }
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'].value = val; this.onFilter(); }, 20)
            }, 600)
        }
    },
    computed: {
/*        ...mapGetters({
            codesCostClass: 'codeStore/costClass'
        })  */
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cost-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .cost-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.cost-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
